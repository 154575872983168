import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/MyNotes-Webapp/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`最基本的Markdown语法，支持直接书写HTML和语法高亮、表格.`}</p>
    <hr></hr>
    <p>{`一个简单的例子:`}</p>
    <pre><code parentName="pre" {...{}}>{`标题
=======

子标题
----------

 * 列表
 * 列表
    1. sdf
    2. 列表项

这里是一个连接 [fifsky](http://fifsky.com)，显示一个\`tag\`

如果不想显示图片的话:

![图片Alt](http://placekitten.com/g/250/250)

 > 这里是引用的内容
 > 这里是引用的内容

`}</code></pre>
    <h2 {...{
      "id": "创建链接"
    }}>{`创建链接`}</h2>
    <hr></hr>
    <p>{`站外链接:`}</p>
    <pre><code parentName="pre" {...{}}>{`[fifsky](http://www.fifsky.com)
`}</code></pre>
    <p>{`wiki链接:`}</p>
    <pre><code parentName="pre" {...{}}>{`[Go to index](index.md)
`}</code></pre>
    <p>{`wiki链接会自动添加hash `}<inlineCode parentName="p">{`#!`}</inlineCode>{` :`}</p>
    <p><a parentName="p" {...{
        "href": "./markdown/"
      }}>{`Go to index`}</a></p>
    <h2 {...{
      "id": "表格"
    }}>{`表格`}</h2>
    <p>{`如果你想显示表格，可以参照下面的例子:`}</p>
    <pre><code parentName="pre" {...{}}>{`| Tables        | Are           | Cool  |
| ------------- |:-------------:| -----:|
| col 3 is      | right-aligned | $1600 |
| col 2 is      | centered      |   $12 |
| zebra stripes | are neat      |    $1 |
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`$1`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2 {...{
      "id": "语法高亮"
    }}>{`语法高亮`}</h2>
    <p>{`一段php语法高亮的例子:`}</p>
    <pre><code parentName="pre" {...{}}>{`\`\`\`php
<?php
    $a = true;
    if($a){
        echo 'hello world';
    }
?>
\`\`\`
`}</code></pre>
    <p>{`render:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`<?php
    $a = true;
    if($a){
        echo 'hello world';
    }
?>
`}</code></pre>
    <p>{`支持语言列表如下:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Language`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Keyword`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bash`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bash`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C#`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`csharp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Clojure`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`clojure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C++`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cpp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CSS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`css`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CoffeeScript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`coffeescript`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CMake`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cmake`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HTML`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`html`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HTTP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`http`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Java`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`java`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JavaScript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`javascript`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`json`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Markdown`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`markdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Objective C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`objectivec`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Perl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`perl`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PHP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`php`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Python`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`python`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ruby`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ruby`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`r`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SQL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sql`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Scala`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`scala`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Vala`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`vala`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`XML`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`xml`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "公式"
    }}>{`公式`}</h2>
    <p>{`你想要玩更高级的功能吗？你可以像下面这样`}</p>
    <pre><code parentName="pre" {...{}}>{`$$ x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a} $$
`}</code></pre>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`gimmick: math`}</a><br parentName="p"></br>{`
`}{`$$ x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a} $$`}</p>
    <p>{`$$ \\frac{\\partial \\phi}{\\partial x} \\vert_b = \\frac{1}{\\Delta x/2}(\\phi_0-\\phi_b) $$`}</p>
    <p>{`$$ \\int u \\frac{dv}{dx}\\,dx=uv-\\int
\\frac{du}{dx}v\\,dx\\lim_{n\\rightarrow \\infty }
\\left (  1 +\\frac{1}{n} \\right )^n
$$`}</p>
    <blockquote>
      <p parentName="blockquote">{`End enjoy!!!`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      